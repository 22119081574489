//ACTIVE STATE HEADER
var topMenu = $("#menunav").find('ul'),
    topMenuHeight = topMenu.outerHeight() + 100,
    menuItems = topMenu.find("li a");
    scrollItems = menuItems.map(function(){
        var url = $(this).attr("href");
        var item = $(url.substring(1));
        if (item.length) { return item; }
    });


// if we have anchor on the url (calling from other page)
if(window.location.hash){
    $('html,body').animate({
        scrollTop:$(window.location.hash).offset().top +'px'
    },1000,'swing');
}


$(window).scroll(function(){
    //ACTIVE STATE HEADER
    var fromTop = $(this).scrollTop() + topMenuHeight;
    var cur = scrollItems.map(function(){
         if ($(this).offset().top < fromTop)
            return this;
    });
    cur = cur[cur.length-1];
    var id = cur && cur.length ? cur[0].id : "";
    menuItems.parent().removeClass("active").end().filter("[href='/#"+id+"']").parent().addClass("active");


    //HEADER SCROLL FIXED
    var scroll = $(window).scrollTop();
    if($('body').hasClass('home')){
        $("header").removeClass("relative");
        if (scroll >= 20) {
            $('header').addClass('fixed');
        }else{
            $("header").removeClass("fixed");
        }
    }else{
        $("header").removeClass("fixed");
        $("header").addClass("relative");
    }
});

$(document).ready(function(){
    //TRIGGER RESPONSIVE MENU
    $('#trigger').click(function(){
        if($('body').hasClass('open')){
            $('body').removeClass('open');
            $('header nav .menuwrapper').slideUp();
        }else{
            $('body').addClass('open');
            $('header nav .menuwrapper').slideDown();
        }
    });

    $('.slider').slick({
      centerMode: true,
      centerPadding: '60px',
      slidesToShow: 3,
      prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left"></i></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right"></i></button>',
      responsive: [
        {
          breakpoint: 1050,
          settings: {
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 2
          }
        },
        {
          breakpoint: 750,
          settings: {
            centerMode: true,
            centerPadding: '40px',
            slidesToShow: 1
          }
        }
      ]
    });

    $('.grid').masonry({
        itemSelector: '.grid-item',
        gutter: 0,
    });

    // Add smooth scrolling to all links
    $('a[href*="#"]').click(function(event) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top - 100
                }, 1000, function() {
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) {
                        return false;
                    } else {
                        $target.attr('tabindex','-1');
                        $target.focus();
                    };
                });
            }
        }
    });

        heightContact();
        HeaderTop();
});



//HEIGHT HEADER
function heightContact(){
    $screenHeight = $(window).height();
    $heightFooter = $('footer').outerHeight();
    $heightHeader = $('header').outerHeight();

    $height = $screenHeight - $heightFooter - $heightHeader;

    $(".Contact .right").css({'height': $height});
    $(".Contact .left").css({'height': $height});
}

function HeaderTop(){
    $heightHeader = $('header').outerHeight();

    $('#Header').css({'margin-top': $heightHeader});
}

$(window).resize(function(){
    heightContact();
    HeaderTop();
});
